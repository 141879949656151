export const yahooRetargetingScript = {
  // Yahoo Site Retargeting tag
  innerHTML: `
      window.yjDataLayer = window.yjDataLayer || [];
      function ytag() { yjDataLayer.push(arguments); }
      ytag({
        "type":"yjad_retargeting",
        "config":{
          "yahoo_retargeting_id": "KRPUE064XT",
          "yahoo_retargeting_label": "",
          "yahoo_retargeting_page_type": "",
          "yahoo_retargeting_items":[
            {item_id: '', category_id: '', price: '', quantity: ''}
          ]
        }
      });
    `,
}

export const yahooRetargetingTag = {
  // Yahoo Site Retargeting tag
  src: "https://s.yimg.jp/images/listing/tool/cv/ytag.js",
  async: true,
}

export const yahooGeneralTag = {
  // Yahoo Site General tag
  innerHTML: `
      window.yjDataLayer = window.yjDataLayer || [];
      function ytag() { yjDataLayer.push(arguments); }
      ytag({"type":"ycl_cookie"});
    `,
}
