// メルカリAD
export const mercariRetargetingTag = {
  // mercari Retargeting tag
  src: "https://tag.eisa.mercari.com/eagletag.js",
  async: true,
}

export const mercariGeneralTag = {
  innerHTML: `
    window.eagletag = window.eagletag || {cmd: []};
    eagletag.cmd.push(function() {
      eagletag.setAccountID('pAenCCcWLxyfk9PN2D6ggc');
      eagletag.writeTrackingID();
      eagletag.run();
    });
  `,
}
